import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../components/layout';

import EVMU from '../components/EVMU';

class Play extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet title="EVMU - Play your favourite VMU games in your browser" />
        <header id="header">
          <h1>EVMU Web</h1>
          <p>Play your favourite VMU games in your browser</p>
        </header>
        <div id="main">
          <section id="content" className="main">
            <div className="evmu-online-play">
              <EVMU />
            </div>
            <h2>How to play</h2>
            <p>
              You can use your keyboard's arrow keys and A/B keys. Controller
              support coming soon.
            </p>
            <h2>How do I play a rom?</h2>
            <p>Simply drag and your rom files onto the VMU screen above.</p>
          </section>
        </div>
      </Layout>
    );
  }
}

export default Play;
